import { Component, OnInit, Input, Inject } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { FormControl, FormArray, ValidatorFn, Validators } from "@angular/forms";
import { BuyService } from "../../../../services/buy.service";
import { config } from "../../../../config";
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '../../../../super/snackbar/snackbar.component';
import { MatDialog } from '@angular/material/dialog';
import { RulesOverviewDialog } from './rules/rules-overview-dialog';
import { LoadingBuyDialog } from './loading-buy/loading-buy-dialog';
import { WINDOW } from '@ng-toolkit/universal';
@Component({
  selector: "app-buy",
  templateUrl: "./buy.component.html",
  styleUrls: ["./buy.component.scss"],
})
export class BuyComponent implements OnInit {
  form: FormGroup;
  promotionForm: FormGroup;
  pluginForm: FormGroup;
  infoForm: FormGroup;
  constructor(@Inject(WINDOW) private window: Window, private fb: FormBuilder,
    private buyService: BuyService,
    public snackBar: MatSnackBar,
    public dialog: MatDialog
  ) { }
  apiUrl = config.apiUrl;
  currencyUnit = config.currencyUnit;
  packagesWithProducts: any[];
  packageIsSelected = { name: "" };
  packagesWithProductsControls: any[];
  initDeliveryPrice = 0;
  selectedId: any = 0;
  packageName: string = '';
  //packagePrice
  productPrices: {};
  mainPrice = {};
  loadingMessage: any;
  selectedOptions = {
    "packageId": -1,
    "products": [],
    "discountCode": "",
    "paymentMethod": 1,
    "paymentInfo": {
      "callback": ''
    },
    "info": {
      "name": "",
      "phone": "",
      "office": "",
      "address": ""
    }
  };
  totalPrice = 0;
  promotionCode = '';
  promotionedPrice = 0;
  constPrice = 0;
  //todo: removing children options
  childrenOptions = {};
  extraProductPrice = {};
  title;
  ngOnInit() {

    this.promotionForm = this.fb.group({
      promotionCode: [null]
    });
    this.form = this.fb.group({
      agree: [false, {
        validators: Validators.required
      }]
    });
    this.pluginForm = this.fb.group({});
    this.infoForm = this.fb.group({
      name: [null, {
        validators: Validators.required
      }],
      office: [null, {
        validators: Validators.required
      }],
      phone: [null, {
        validators: Validators.required
      }],
      address: [null, {
        validators: Validators.required
      }]
    });

    this.getData();
  }
  getData() {
    this.loadingMessage = true;
    //packages:packagesWithProducts[0],produts:packagesWithProducts[1]
    // ,extraProducts:packagesWithProducts[2],categories:packagesWithProducts[3]
    this.buyService.getPackagesWithProducts().subscribe(response => {
      this.loadingMessage = false;
      this.packagesWithProducts = response;
      this.addControlsPlgins();
    });
  }
  private addControlsPlgins() {
    let tempObj = {};
    this.packagesWithProducts[1].forEach((product, i) => {
      if (product.id) {
        const fc = new FormControl(false);
        this.form.addControl(product.id.toString(), fc);
        tempObj[product.id] = product.price;
        this.childrenOptions[product.id.toString()] = [];
      }
      if (product.plugins.length > 0) {
        product.plugins.forEach(plugin => {
          const fc = new FormControl(false);
          if (plugin.parentId) {
            this.childrenOptions[plugin.parentId].push(plugin.id);
          }
          tempObj[plugin.id] = plugin.price;
          this.pluginForm.addControl(plugin.id.toString(), fc);
          this.pluginForm.get(plugin.id.toString()).disable();
        });
      }
    });
    this.packagesWithProducts[2].forEach((extraProduct, i) => {
      const fc = new FormControl(0);
      this.selectedOptions.products.push({ 'id': extraProduct.id, 'count': 0 });
      this.extraProductPrice[extraProduct.id.toString()] = extraProduct.price;
      this.form.addControl(extraProduct.id, fc);
    });
    this.packagesWithProducts[3].forEach((category, i) => {
      const fc = new FormControl(category.products[0].id.toString());
      this.selectedOptions.products.push({ 'id': category.products[0].id });
      this.totalPrice += category.products[0].price;
      this.form.addControl(category.id, fc);
    });
    this.mainPrice = tempObj;
  }

  selectPackage(packageSelected, state) {
    if (!state) {
      return;
    }
    document.querySelector("#products").scrollIntoView({ behavior: "smooth" });
    this.productPrices = {};
    this.selectedOptions.products = [];
    this.promotionedPrice = 0;
    this.extraProductPrice = {};
    this.totalPrice = 0;
    this.constPrice = 0;
    let tempObj = {};
    this.form.enable();
    this.pluginForm.disable();
    this.form.reset();
    this.pluginForm.reset();
    this.packagesWithProducts[3].forEach(category => {
      this.selectedOptions.products.push({ 'id': category.products[0].id })
      this.form.get(category.id.toString()).setValue(category.products[0].id.toString());
      this.totalPrice += category.products[0].price;
      if (!category.products[0]['isDiscountable']) {
        this.constPrice += category.products[0].price;
      }
    })
    if (packageSelected == -1) {
      this.form.enable();
      this.selectedId = -1;
      return;
    }
    this.selectedId = packageSelected.id;
    this.packageIsSelected = packageSelected;

    if (this.packageIsSelected['products'].length > 0) {
      this.packageIsSelected['products'].forEach((item, index) => {
        if (item.Product.isDiscountable == false) {
          this.constPrice += item.price;
        }
        let pId = item.Product.id.toString();
        if (item.Product.type == 1) {
          this.form.get(pId).setValue(true);
          this.form.get(pId).disable();
          this.enableChildOptions(pId, this.form.get(pId).value);
          this.selectedOptions.products.push({ 'id': item.Product.id });

          // this.selectedOptions.products = this.selectedOptions.products.filter(product => product.id == item.Product.id);
          if (this.mainPrice[item.Product.id] > item.price) {
            tempObj[item.Product.id] = item.price;
          }
          this.totalPrice += item.price;
        }
        if (item.Product.type == 2) {
          this.pluginForm.get(pId).setValue(true);
          this.pluginForm.get(pId).disable();
          this.selectedOptions.products.push({ 'id': item.Product.id });
          if (this.mainPrice[item.Product.id] > item.price) {
            tempObj[item.Product.id] = item.price;
          }
          this.totalPrice += item.price;
        }
        if (item.Product.type == 4) {
          let product = '';
          this.packagesWithProducts[3].forEach(category => {
            let cId = category.id.toString();
            product = category.products.filter((product) => {
              return product.id == pId;
            })[0];
            if (product) {
              //default value
              this.selectedOptions.products = this.selectedOptions.products.filter(product => product.id !== category.products[0].id);
              this.totalPrice -= category.products[0].price;
              if (!category.products[0]['isDiscountable']) {
                this.constPrice -= category.products[0].price;
              }
              this.form.get(cId).setValue(pId);
              this.form.get(cId).disable();
              this.selectedOptions.products.push({ 'id': item.Product.id });
              this.totalPrice += item.price;
            }
          });
        }
        if (item.Product.type == 3) {
          this.form.get(pId).setValue(item.defaultOrderCount);
          this.form.get(pId).disable();
          this.extraProductPrice[pId] = item.price;
          this.selectedOptions.products.push({ 'id': item.Product.id, 'count': item.defaultOrderCount });
          this.totalPrice += item.price * item.defaultOrderCount;
        }
      });
    }
    this.selectedOptions['packageId'] = this.selectedId;
    this.productPrices = tempObj;
  }

  enableChildOptions(parentId: string | number, state: boolean) {
    if (this.childrenOptions[parentId]) {
      this.childrenOptions[parentId].forEach((element, index) => {
        if (state == true) {
          this.pluginForm.get(element.toString()).enable();
        } else {
          this.selectedOptions.products = this.selectedOptions.products.filter(function (ele) {
            return ele['id'] != element;
          });
          this.pluginForm.get(element.toString()).reset();
          this.pluginForm.get(element.toString()).disable();
        }
      });
    }
  }

  resetFroms() {
    this.form.enable();
    this.pluginForm.enable();
    this.promotionForm.enable();
    this.selectedId = -1;
    this.selectPackage(this.selectedId, true);
    this.promotionForm.reset();
  }

  optionSelected(option) {
    if (this.selectedId == 0) {
      this.packageIsSelected.name = "به سلیقه خودم"
      this.selectedId = -1;
    }
    if (this.childrenOptions[option.id]) {
      this.enableChildOptions(option.id, this.form.get(option.id.toString()).value);
    }
    if (option.type == 1) {
      if (this.form.get(option.id.toString()).value === true) {
        this.computingTotalPrice(option, true);
      } else {
        this.computingTotalPrice(option, false);
      }
    }
    if (option.type == 2) {
      if (this.pluginForm.get(option.id.toString()).value === true) {
        this.computingTotalPrice(option, true);
      } else {
        this.computingTotalPrice(option, false);
      }
    }
  }

  showDialog(): void {
    const dialogRef = this.dialog.open(RulesOverviewDialog, {
      width: 'auto'
    });
  }
  computingTotalPrice(option, state) {
    if (state) {
      this.selectedOptions.products.push({ 'id': option.id });
      if (option.isDiscountable == false) {
        this.constPrice += option.price;
      }
      this.totalPrice += option.price;
    }
    else {
      this.selectedOptions.products = this.selectedOptions.products.filter(function (ele) {
        return ele['id'] != option.id;
      });
      this.totalPrice -= option.price;
    }
  }

  //for category options
  categorySelected(event, category) {
    let item = { id: '', price: 0 };
    let newSelected = { id: '', price: 0 };
    //removing prev Select
    item = category.products.filter((item) => {
      return item.id == this.form.get(category.id.toString()).value;
    })[0];
    if (item) {
      this.totalPrice -= item.price;
      this.selectedOptions.products = this.selectedOptions.products.filter(function (ele) {
        return ele['id'] != item.id;
      });
    }
    //adding new Select
    newSelected = category.products.filter((item) => {
      return item.id == event.value;
    })[0];
    this.selectedOptions.products.push({ 'id': event.value });
    this.totalPrice += newSelected.price;
  }

  changeNmuber(option) {
    let item: { count: number };
    item = this.selectedOptions.products.filter((item) => {
      return item.id == option.id;
    })[0];
    if (item) {
      let newVal = 0;
      newVal = this.form.get(option.id.toString()).value - item.count;
      if (newVal > 0) {
        this.totalPrice += newVal * option.price;
      } else {
        this.totalPrice -= Math.abs(newVal) * option.price;
      }
      item['count'] = this.form.get(option.id.toString()).value;
    }
    else {
      this.totalPrice += this.form.get(option.id.toString()).value * option.price;
      this.selectedOptions.products.push({ 'id': option.id, 'count': this.form.get(option.id.toString()).value });
    }
  }

  payFactor() {
    this.selectedOptions.info.name = this.infoForm.get('name').value;
    this.selectedOptions.info.phone = this.infoForm.get('phone').value;
    this.selectedOptions.info.office = this.infoForm.get('office').value;
    this.selectedOptions.info.address = this.infoForm.get('address').value;
    this.selectedOptions.discountCode = this.promotionForm.get('promotionCode').value;
    const url = this.window.location.href;
    this.selectedOptions.paymentInfo.callback = url.split('/buy')[0] + "/verify";
    if (!this.form.get('agree').value) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'لطفا قوانین و مقررات مطالعه فرمایید',
        panelClass: 'errorSnakbar',
        duration: 1000
      });
    }
    else if (this.selectedId == 0) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'هیچ محصولی انتخاب نکرده اید',
        panelClass: 'errorSnakbar',
        duration: 1000
      });
    }
    else if (!this.infoForm.valid) {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'لطفا فیلد های ستاره دار را تکمیل کنید',
        panelClass: 'errorSnakbar',
        duration: 1000
      });
    }
    else {
      const dialogRef = this.dialog.open(LoadingBuyDialog, {
        width: 'auto'
      });
      this.buyService.sendOrder(this.selectedOptions).subscribe(response => {
        this.window.location.href = response.redirect;
      });
    }

  }

  sendPromotionCode() {
    if (this.selectedId) {
      if (this.selectedId == -1) {
        this.selectedId = '';
      }
      this.buyService.sendPromotionCode(this.promotionForm.get('promotionCode').value, this.selectedId).subscribe(response => {
        let price = 0;
        price = this.totalPrice - this.constPrice;
        price *= (1 - (response.amount / 100));
        this.promotionedPrice = price + this.constPrice;
        this.promotionForm.get('promotionCode').disable();
        this.form.disable();
        this.pluginForm.disable();
        this.form.get('agree').enable();
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: `  ${response.name}  تایید شد
          و تخفیف ${response.amount}% اعمال شد.`,
          panelClass: 'successSnakbar',
          duration: 1000
        });
      },
        err => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            data: 'کد تخفیف مرتبط با بسته انتخاب شده نیست',
            panelClass: 'errorSnakbar',
            duration: 10000
          });
        })
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'لطفا یک پکیج را انتخاب کنید',
        panelClass: 'errorSnakbar',
        duration: 10000
      });
    }
  }
}


