import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { config } from "../../config";
@Component({
  selector: 'preview-dialog',
  templateUrl: 'preview-dialog.html',
  styleUrls: ['./preview-dialog.scss']
})
export class PreviewDialog {
  index = 0;
  item = "";
  allItems = [];
  @ViewChild("next") nextButton: ElementRef;
  @ViewChild("prev") prevButton: ElementRef;
  @ViewChild("iframe") iframe: ElementRef;
  apiUrl = config.apiUrl;

  constructor(
    public dialogRef: MatDialogRef<PreviewDialog>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  ngOnInit() {
    this.index = this.data.index;
    console.log(this.data.allItems[this.index])
    this.item = this.data.allItems[this.index];
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  nextImg() {
    if (this.index < this.data.allItems.length - 1) {
      if (this.prevButton.nativeElement.disabled) {
        this.prevButton.nativeElement.disabled = false;
      }
      this.index++;
      this.item = this.data.allItems[this.index];
    } else {
      this.nextButton.nativeElement.disabled = true;
    }

  }
  prevImg() {
    if (this.index > 0) {
      if (this.nextButton.nativeElement.disabled) {
        this.nextButton.nativeElement.disabled = false;
      }
      this.index--;
      this.item = this.data.allItems[this.index];
    } else {
      this.prevButton.nativeElement.disabled = true;
    }
  }
}