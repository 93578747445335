import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material';
import { SnackbarComponent } from '../super/snackbar/snackbar.component';

@Injectable({
	providedIn: 'root'
})
export class ErrorService {

	constructor(
		public snackBar: MatSnackBar
	) {
	}

	handleError(response: HttpErrorResponse) {
		let err = null;
		if (response.error instanceof ErrorEvent) {
			err = response.error.message;
		} else {
			if (response.status == 0) {
				err = 'خطا در برقراری ارتباط';
			} else {
				err = response.error.meta.error_message
			}
		}

		// this.globalsService.setError(err);
		this.snackBar.openFromComponent(SnackbarComponent, {
			data: err,
			panelClass: 'errorSnakbar',
			duration: 10000
		});
		return throwError({
			message: err,
			code: response.error.meta.code
		});
	}

}
