import { Component, OnDestroy, OnInit, Input, Inject } from '@angular/core';
import { WINDOW } from '@ng-toolkit/universal';

@Component({
  selector: 'app-countdown-timer',
  template: '<p>{{message}}</p>'
})
export class CountdownTimerComponent implements OnInit, OnDestroy {

  intervalId = 0;
  message = '';
  distance;
  @Input() date: string;
 constructor(@Inject(WINDOW) private window: Window) {}

  clearTimer() { clearInterval(this.intervalId); }

  ngOnInit() { this.start(); }
  ngOnDestroy() { this.clearTimer(); }

  start() {
    this.countDown();
  }
  stop() {
    this.clearTimer();
  }
  twoDigit = function (number) {
    return number > 9 ? "" + number : "0" + number;
  };
  private countDown() {
    this.intervalId = this.window.setInterval(() => {


      let countDownDate = new Date(this.date).getTime();

      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      let days = this.twoDigit(Math.floor(distance / (1000 * 60 * 60 * 24)));
      let hours = this.twoDigit(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
      let minutes = this.twoDigit(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
      let seconds = this.twoDigit(Math.floor((distance % (1000 * 60)) / 1000));

      // Output the result in an element with id="demo"
      this.message = days + "روز: " + hours + "ساعت: "
        + minutes + "دقیقه: " + seconds + "ثانیه ";

      if (distance < 0) {
        this.stop();
        this.message = 'مهلت به اتمام رسید!';
      }


    }, 1000);
  }
}