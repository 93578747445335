import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { ContentsService } from "../../../../services/contents.service";
import { config } from "../../../../config";
import { MatSidenav } from '@angular/material';
import { SidenavService } from '../../../../services/sideNav.service';


@Component({
  selector: "cpy-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"]
})
export class HomeComponent implements OnInit {
  @ViewChild('rightSidenav') public sidenav: MatSidenav;
  constructor(
    // it's used in component's template
    public router: Router,
    private contentsService: ContentsService,
    private sidenavService: SidenavService
  ) { }
  festivalAlert = false;
  bottomBanner = "";
  apiUrl = config.apiUrl;
  ngOnInit() {
    this.sidenavService.setSidenav(this.sidenav);
    setTimeout(function () {
      this.festivalAlert = true;
    }.bind(this), 6000);
    this.contentsService.getContentBySlug("bottom-banner").subscribe(content => {
      this.bottomBanner = content;
    });
  }

  showAlert() {
    this.festivalAlert = !this.festivalAlert;
  }
  links = [
    { title: 'صفحه اصلی', href: '/' },
    { title: 'معرفی چاپیار', href: '/intro' },
    { title: 'مرکز دانلود', href: '/downloads' },
    { title: 'گالری', href: '/gallary' },
    { title: 'خرید نرم افزار', href: '/buy' },
    { title: 'رضایت نامه', href: '/appreciation' },
    { title: 'درباره ما', href: '/aboutus' },
    { title: 'تماس با ما', href: '/contactus' },
    // { title: 'سوالات متداول', href: '/faq' }
  ];
}
