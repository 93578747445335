import { PLATFORM_ID, APP_ID, Inject, NgModule } from "@angular/core";
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { BrowserModule } from '@angular/platform-browser';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { NgtUniversalModule } from '@ng-toolkit/universal';
import { HttpClientModule } from '@angular/common/http';
import { DefaultModuleRouting } from "./default.module.routing";
import { CoreModule } from "../../core/core.module";
import { AppComponent } from "../../app.component";
import { WINDOW } from '@ng-toolkit/universal';

import { HomeComponent } from "./components/home/home.component";
import { HomeContentComponent } from "./components/home-content/home-content.component";
import { HeaderComponent } from "./components/header/header.component";
import { FooterComponent } from "./components/footer/footer.component";
import { MainSliderComponent } from "./components/main-slider/main-slider.component";
import { DownloadCenterComponent } from "./components/download-center/download-center.component";
import { GallaryComponent } from "./components/gallary/gallary.component";
import { AppreciationComponent } from "./components/appreciation/appreciation.component";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { ContactusComponent } from "./components/contactus/contactus.component";
import { AboutusComponent } from "./components/aboutus/aboutus.component";
import { FaquestionComponent } from "./components/faquestion/faquestion.component";
import { PageComponent } from "./components/page/page.component";
import { BuyComponent } from './components/buy/buy.component';
import { RulesOverviewDialog } from './components/buy/rules/rules-overview-dialog';
import { LoadingBuyDialog } from './components/buy/loading-buy/loading-buy-dialog';
import { SnackbarComponent } from '../../super/snackbar/snackbar.component';
import { LoadingComponent } from '../../super/loading/loading.component';
import { PreviewDialog } from '../../super/preview-dialog/preview-dialog.component';
import { CountdownTimerComponent } from '../../super/countdown-timer/countdown-timer.component';
import { VerifyComponent } from './components/verify/verify.component';
@NgModule({
  declarations: [
    // @Components (Theme)
    HomeComponent,
    HomeContentComponent,
    HeaderComponent,
    FooterComponent,
    MainSliderComponent,
    DownloadCenterComponent,
    GallaryComponent,
    AppreciationComponent,
    IntroductionComponent,
    ContactusComponent,
    AboutusComponent,
    FaquestionComponent,
    PreviewDialog,
    RulesOverviewDialog,
    LoadingBuyDialog,
    PageComponent,
    BuyComponent,
    //super
    SnackbarComponent,
    CountdownTimerComponent,
    LoadingComponent,
    VerifyComponent

  ],
  entryComponents: [GallaryComponent,
    RulesOverviewDialog, SnackbarComponent, CountdownTimerComponent, PreviewDialog, LoadingBuyDialog],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CoreModule, DefaultModuleRouting,
    CommonModule,
    TransferHttpCacheModule,
    HttpClientModule,
    NgtUniversalModule
  ],

  providers: [],
  bootstrap: [AppComponent]
})
export class DefaultModule {
  
}
