import "hammerjs";
import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

// import { AppModule } from "./app/app.module";
import { DefaultModule } from "../src/app/themes/default/default.module";
import { environment } from "./environments/environment";
import { AppBrowserModule } from './app/app.browser.module';

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(DefaultModule)
    .then(ref => {
      if (window["ngRef"]) {
        window["ngRef"].destroy();
      }
      window["ngRef"] = ref;
    })
    .catch(err => console.error(err));
});
