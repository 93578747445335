import { Component, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from "@angular/router";
import { BuyService } from "../../../../services/buy.service";

@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
  status = { message: '', status: '' };
  constructor(private router: Router,
    private buyService: BuyService) {
  }

  ngOnInit() {
    const snapshot: RouterStateSnapshot = this.router.routerState.snapshot;
    if (snapshot.root.queryParams) {
      let payDetailes = { authority: snapshot.root.queryParams.Authority, status: snapshot.root.queryParams.Status }
      this.buyService.sendPeyDetailes(JSON.stringify(payDetailes)).subscribe(response => { console.log(response); this.status.status = 'sucess'; this.status.message = `شماره پیگیری شما: ${response.ref}` },
        err => { this.status.status = 'error'; this.status.message = err.message; });
    }
  }

}
