import { Component, OnInit } from "@angular/core";
import { ContentsService } from "../../../../services/contents.service";
import { config } from "../../../../config";

@Component({
  selector: "app-main-slider",
  templateUrl: "./main-slider.component.html",
  styleUrls: ["./main-slider.component.scss"]
})
export class MainSliderComponent implements OnInit {
  // slidesStore = [1, 2, 3].map(item => `/assets/imgs/slide0${item}.jpg`);
  slidesStore = "";
  apiUrl = config.apiUrl;
  customOptions = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 9000,
    margin: 30,
    autoplay: true,
    items: 1,
    navText: ["قبلی", "بعدی"],
    nav: false
  };
  constructor(private contentsService: ContentsService) {
    this.contentsService.getContents("first-page-slider").subscribe(slider => {
      this.slidesStore = slider;
    });
  }

  ngOnInit() { }
}
