import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ContentsService } from "../../../../services/contents.service";
import { config } from "../../../../config";
import { IContent } from "../../../../interfaces/content";

@Component({
  selector: "app-page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"]
})
export class PageComponent implements OnInit {
  constructor(private route: ActivatedRoute, private ContentsService: ContentsService) { }
  content: IContent;
  apiUrl = config.apiUrl;
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      this.ContentsService.getContentBySlug(slug).subscribe(IContent => {
        this.content = IContent;
      });
    });
  }
}
