import { Component, OnInit } from "@angular/core";
import { ContentsService } from "../../../../services/contents.service";
@Component({
  selector: "app-download-center",
  templateUrl: "./download-center.component.html",
  styleUrls: ["./download-center.component.scss"]
})
export class DownloadCenterComponent implements OnInit {
  customOptions2 = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 1,
    navText: ["قبلی", "بعدی"],
    nav: false
  };
  downloads = [];
  downloadItems = [];
  rawData;
  // downloads = [
  //   [
  //     [
  //       { title: "test1", href: "test1" },
  //       { title: "test2", href: "test2" },
  //       { title: "test3", href: "test3" },
  //       { title: "test4", href: "test4" },
  //       { title: "test5", href: "test5" }
  //     ],
  //     [
  //       { title: "test6", href: "test6" },
  //       { title: "test7", href: "test7" },
  //       { title: "test8", href: "test8" },
  //       { title: "test9", href: "test9" },
  //       { title: "test10", href: "test10" }
  //     ]
  //   ],
  //   [
  //     [
  //       { title: "test10", href: "test1" },
  //       { title: "test11", href: "test2" },
  //       { title: "test12", href: "test3" },
  //       { title: "test13", href: "test4" },
  //       { title: "test14", href: "test5" }
  //     ],
  //     [
  //       { title: "test15", href: "test6" },
  //       { title: "test16", href: "test7" },
  //       { title: "test17", href: "test8" },
  //       { title: "test18", href: "test9" },
  //       { title: "test19", href: "test10" }
  //     ]
  //   ]
  // ];
  constructor(private ContentsService: ContentsService) { }

  ngOnInit() {
    this.ContentsService.getContents("download").subscribe(download => {
      let tempArray = [];
      this.rawData = download;
      if (download) {
        if (download.length < 5) {
          this.downloads = [download];
        } else {
          for (let i = 0; i < download.length; i++) {
            tempArray.push(download[i]);
            if ((i !== 0 && (i + 1) % 5 == 0)) {
              this.downloads.push(tempArray);
              tempArray = [];
            }
            else if (download.length % 5 !== 0 && download.length - i < 5) {
              this.downloads.push(tempArray);
              tempArray = [];
            }
          }
        }
        tempArray = [];
        for (let j = 0; j < this.downloads.length; j++) {
          tempArray.push(this.downloads[j]);
          if ((j !== 0 && (j + 1) % 2 == 0) || this.downloads.length - j < 2) {
            this.downloadItems.push(tempArray);
            tempArray = [];
          }
        }
      }
    });
  }
  mobileOptions = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 1,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      }
    }
  }
}
