import { Component, OnInit, ViewChild, Inject } from "@angular/core";
// import {TranslateService} from '@ngx-translate/core';
import { WINDOW } from '@ng-toolkit/universal';
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: "app-root",
  template: `<router-outlet></router-outlet>`,
  host: { "[class.rtl]": "this.isRtl" }
})
export class AppComponent implements OnInit {
  isRtl: boolean;

  constructor(@Inject(WINDOW) private window: Window,
    // public translate: TranslateService,
    private router: Router
  ) {
    // translate.addLangs(['en', 'fa']);
    // translate.setDefaultLang('en');
    // const browserLang = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fa/) ? browserLang : 'en');
    // translate.use('fa');
  }

  ngOnInit() {
    // this.isRtl = this.translate.currentLang === 'fa';
    // console.log(`[cpy] >>> Website current language: "${this.translate.currentLang}".`);
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.window.scrollTo(0, 0);
    });
  }
}
