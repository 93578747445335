import { NgModule, APP_INITIALIZER, LOCALE_ID, PLATFORM_ID, APP_ID, Inject } from "@angular/core";
import { isPlatformBrowser, CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "../app.component";
import { HomeComponent } from "../themes/default/components/home/home.component";
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS
} from "@angular/common/http";
import { CurrencyUnitPipe } from '../pipes/currency-unit.pipe';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { JalaliPipe } from '../pipes/jalali.pipe';
import { SafePipe } from '../pipes/safe.pipe';

import { CarouselModule } from "ngx-owl-carousel-o";
import { BrowserModule } from "@angular/platform-browser";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatListModule } from "@angular/material/list";
import { MatTabsModule } from "@angular/material/tabs";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from "@angular/material/radio";
import { MatInputModule } from "@angular/material/input";
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { SidenavService } from '../services/sideNav.service';
import { LoaderInterceptorService } from "../services/loading-interceptor.service";
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';

import { ScrollSpyDirective } from "../directives/scroll-spy.directive.js";

@NgModule({
  declarations: [
    AppComponent,
    //directive
    ScrollSpyDirective,
    // @Pipes
    CurrencyUnitPipe,
    TruncatePipe,
    JalaliPipe,
    SafePipe
  ],
  imports: [
    RouterModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    //owl carousel
    CarouselModule,

    CommonModule,
    FlexLayoutModule,
    //Material Module
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatDialogModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSnackBarModule,
    MatCardModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    // i18n related
    HttpClientModule
  ],
  exports: [
    AppComponent,
    CommonModule,

    //owl carousel
    CarouselModule,
    // @Pipes
    CurrencyUnitPipe,
    TruncatePipe,
    JalaliPipe,
    SafePipe,

    FlexLayoutModule,
    // material module
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatTabsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCheckboxModule,
    MatRadioModule,
    MatInputModule,
    MatSnackBarModule,
    MatCardModule,
    MatPaginatorModule,
    MatTooltipModule,
    MatSidenavModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    //Directives
    ScrollSpyDirective
  ],
  providers: [SidenavService, {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptorService,
    multi: true
  }]
})
export class CoreModule {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(APP_ID) private appId: string) {
    const platform = isPlatformBrowser(platformId) ?
      'in the browser' : 'on the server';
    console.log(`Running ${platform} with appId=${appId}`);
  }
}
