import { Component, OnInit } from "@angular/core";
import {SnackbarComponent} from "../../../../super/snackbar/snackbar.component";
import {FormBuilder, FormGroup} from "@angular/forms";
import {MatSnackBar} from "@angular/material/snack-bar";
import {InboxService} from "../../../../services/inbox.service";

@Component({
  selector: "app-faquestion",
  templateUrl: "./faquestion.component.html",
  styleUrls: ["./faquestion.component.scss"]
})
export class FaquestionComponent implements OnInit {
  messageForm: FormGroup;
  constructor(private formBuilder: FormBuilder,
              public snackBar: MatSnackBar,
              private inboxService: InboxService) {
  }
  accord = 0;
  accordUseful = -1;
  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      name: [],
      phone: [],
      subject: [],
      email: [],
      message: [],
    });
  }

  sendMessage() {
    let formData = this.messageForm.value;
    formData.type = 2;
    this.inboxService.sendMessage(formData).subscribe(() => {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'پیام با موفقیت ثبت شد',
        panelClass: 'successSnakbar',
        duration: 1000
      });
      this.messageForm.reset();
    });
  }
}
