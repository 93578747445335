import { Component, OnInit } from '@angular/core';
import { SidenavService } from '../../../../services/sideNav.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  mobileMenu = false;
  links = [
    { title: 'صفحه اصلی', href: '/' },
    // { title: 'معرفی چاپیار', href: '/intro' },
    { title: 'دانشنامه چاپیار', href: 'https://wiki.chapyar.com/' },
    { title: 'مرکز دانلود', href: '/downloads' },
    { title: 'گالری', href: '/gallary' },
    { title: 'خرید نرم افزار', href: '/buy' },
    { title: 'رضایت نامه', href: '/appreciation' },
    { title: 'درباره ما', href: '/aboutus' },
    { title: 'تماس با ما', href: '/contactus' },
    // { title: 'سوالات متداول', href: '/faq' }
  ];
  constructor(private sidenav: SidenavService) { }

  ngOnInit() { }
  toggleActive: boolean = false;

  toggleRightSidenav() {
    this.toggleActive = !this.toggleActive;
    this.sidenav.toggle();
  }
  menuToggle() {
    this.mobileMenu = !this.mobileMenu;
  }
}
