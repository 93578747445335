import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { config } from "../config";
import { map } from "rxjs/operators";
import { Response } from "../interfaces/response";
import { mergeMap } from 'rxjs/operators';

@Injectable({
  providedIn: "root"
})
export class ContentsService {
  constructor(private http: HttpClient) { }
  getContents(sectionType): Observable<any> {
    return this.http
      .get<Response<any>>(`${config.apiUrl}/content/category/` + sectionType)
      .pipe(map(response => response.data));
  }
  getContentBySlug(slug): Observable<any> {
    return this.http
      .get<Response<any>>(`${config.apiUrl}/content/slug/` + slug)
      .pipe(map(response => response.data[0]));
  }

  getChildrenCategory(slug): Observable<any> {
    return this.http
      .get<Response<any>>(`${config.apiUrl}/category/${slug}/children`)
      .pipe(map(response => response.data));
  }
}
