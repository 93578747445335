import { Component, OnInit, ViewChild } from "@angular/core";
import { ContentsService } from "../../../../services/contents.service";
import { config } from "../../../../config";
import { MatPaginator } from "@angular/material";
import { IContent } from "../../../../interfaces/content";

@Component({
  selector: "app-home-content",
  templateUrl: "./home-content.component.html",
  styleUrls: ["./home-content.component.scss"]
})
export class HomeContentComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  news: any[];
  downloadsCenter: any[];
  clientsComments: any[];
  currentSection;
  apiUrl = config.apiUrl;
  intros: IContent[];
  downloads: IContent[]
  comments;
  ourClients: IContent[];
  aboutUs: IContent;
  pageSize = 10;
  currentPage = 0;
  totalSize = 0;
  array: any;
  displayedColumns = ['', '', '', '', ''];
  dataSource: any;
  part: any;
  downloadOption = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 5,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      },
      940: {
        items: 5
      }
    }
  };
  costumerOption = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 1000,
    margin: 30,
    autoplay: true,
    items: 5,
    navText: ["قبلی", "بعدی "],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 5
      }
    }
  };
  commentOption = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 2,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    }
  };
  newsOptions = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 3,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
  };

  constructor(private contentsService: ContentsService) { }

  ngOnInit() {
    this.contentsService.getContents("news").subscribe(news => {
      this.news = news;
    });
    this.contentsService.getContents("release-log").subscribe(intros => {
      // this.dataSource = intros;
      // this.dataSource.paginator = this.paginator;
      this.array = intros;
      this.totalSize = this.array.length;
      this.intros = intros;
      this.iterator();
    });
    this.contentsService.getContents("download").subscribe(downloads => {
      this.downloads = downloads;
    });
    this.contentsService.getContents("customers").subscribe(comments => {
      this.comments = comments;
    });
    this.contentsService.getContents("our-customers").subscribe(ourClients => {
      this.ourClients = ourClients;
    });
    this.contentsService.getContentBySlug("about-us").subscribe(aboutUs => {
      this.aboutUs = aboutUs;
    });

  }


  public handlePage(e: any) {
    this.currentPage = e.pageIndex;
    this.pageSize = e.pageSize;
    this.iterator();
  }

  private iterator() {
    const end = (this.currentPage + 1) * this.pageSize;
    const start = this.currentPage * this.pageSize;
    console.log('start', start, end, this.intros)
    this.part = this.intros.slice(start, end);
    console.log(this.part);
    // this.intros = part;
  }

  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  scrollTo(section) {
    document
      .querySelector("#" + section)
      .scrollIntoView({ behavior: "smooth" });
  }
}
