import { Component, OnInit } from '@angular/core';
import {ContentsService} from "../../../../services/contents.service";
import {config} from "../../../../config";

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  contents;
  apiUrl = config.apiUrl;
  constructor(private ContentsService: ContentsService) {}

  ngOnInit() {
    this.ContentsService.getContents("intro").subscribe(contents => {
      this.contents = contents;
    })
  }

}
