import { Component, OnInit } from '@angular/core';
import { config } from "../../../../config";
import { ContentsService } from "../../../../services/contents.service";
import { IContent } from "../../../../interfaces/content";

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {
  team: IContent[];
  apiUrl = config.apiUrl;
  aboutUs: IContent;
  constructor(private contentsService: ContentsService) { }

  ngOnInit() {
    this.contentsService.getContents("our-team").subscribe(contents => {
      this.team = contents;
    });
    this.contentsService.getContentBySlug("about-us").subscribe(aboutUs => {
      this.aboutUs = aboutUs;
    });
  }

}
