import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContentsService } from "../../../../services/contents.service";
import { IChildren } from "../../../../interfaces/children";
import { config } from "../../../../config";
import { PreviewDialog } from '../../../../super/preview-dialog/preview-dialog.component'

export interface DialogData {
  length: number;
  animal: string;
  name: string;
}

@Component({
  selector: 'app-gallary',
  templateUrl: './gallary.component.html',
  styleUrls: ['./gallary.component.scss']
})
export class GallaryComponent implements OnInit {
  parentTab: IChildren[];
  apiUrl = config.apiUrl;
  imgs = [];
  rawData = [];
  structs = [{
    title: 'عکس های نرم افزار',
    sections: [],
    content: [],
  }, {
    title: 'فیلم های نرم افزار',
    sections: [],
    content: [],
  }]
  constructor(public dialog: MatDialog,
    private contentsService: ContentsService) { }
  showImage(target): void {
    let targetIndex = 0;
    targetIndex = this.rawData.indexOf(target);
    const dialogRef = this.dialog.open(PreviewDialog, {
      width: 'auto',
      data: { allItems: this.rawData, index: targetIndex }
    });
  }
  changeParentTab(index: number) {
    this.getContentByCategory(this.structs[index].sections[0].slug);
  }
  ngOnInit() {
    this.contentsService.getChildrenCategory("45").subscribe(mainTabs => {
      this.structs[0].sections = mainTabs;
      this.getContentByCategory(this.structs[0].sections[0].slug);
    });
    this.contentsService.getChildrenCategory("43").subscribe(mainTabs => {
      this.structs[1].sections = mainTabs;
    });

  }

  getContentByCategory(slug) {
    this.contentsService.getContents(slug).subscribe(contents => {
      let tempArray = [];
      let appreciations = [];
      this.imgs = [];
      this.rawData = contents;
      console.log(this.rawData);
      if (contents.length < 4) {
        this.imgs = contents;
      } else {
        for (let i = 0; i < this.rawData.length; i++) {
          tempArray.push(this.rawData[i]);
          if ((i !== 0 && (i + 1) % 3 == 0)) {
            appreciations.push(tempArray);
            tempArray = [];
          }
          else if (contents.length % 3 !== 0 && contents.length - i < 3) {
            appreciations.push(tempArray);
            tempArray = [];
          }
        }
        tempArray = [];
        if (appreciations.length > 0) {
          for (let j = 0; j < appreciations.length; j++) {
            tempArray.push(appreciations[j]);
            if ((j !== 0 && (j + 1) % 2 == 0) || appreciations.length - j < 2) {
              this.imgs.push(tempArray);
              tempArray = [];
            }
          }
        }
        console.log(this.imgs);
      }
    });
  }

  mobileOptions = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 2,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    }
  }
}
