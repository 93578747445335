import { Component, OnInit } from '@angular/core';
import { ContentsService } from '../../../../services/contents.service';
import { IContent } from "../../../../interfaces/content";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  aboutUs: IContent;
  constructor(private contentsService: ContentsService) { }

  ngOnInit() {
    this.contentsService.getContentBySlug('about-us').subscribe(aboutUs => {
      this.aboutUs = aboutUs;
    });
  }
  links = [
    { title: 'صفحه اصلی', href: '/' },
    { title: 'معرفی چاپیار', href: '/intro' },
    { title: 'مرکز دانلود', href: '/downloads' },
    { title: 'گالری', href: '/gallary' },
    { title: 'خرید', href: '/buy' },
    { title: 'رضایت نامه', href: '/appreciation' },
    { title: 'درباره ما', href: '/aboutus' },
    { title: 'تماس با ما', href: '/contactus' },
    // { title: 'سوالات متداول', href: '/faq' }
  ];
}
