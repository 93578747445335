import { Component, OnInit, Input } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';
import { ContentsService } from "../../../../../services/contents.service";
import { IContent } from "../../../../../interfaces/content";

@Component({
  selector: 'rules-overview-dialog',
  templateUrl: 'rules-overview-dialog.html',
  styleUrls: ['./rules-overview-dialog.scss']
})
export class RulesOverviewDialog {
  index = 0;
  rules: IContent;

  constructor(
    public dialogRef: MatDialogRef<RulesOverviewDialog>,
    private contentsService: ContentsService
  ) { }
  ngOnInit() {
    this.contentsService.getContentBySlug("rules").subscribe(content => {
      this.rules = content;
    });
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}