import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { HomeContentComponent } from "./components/home-content/home-content.component";
import { DownloadCenterComponent } from "./components/download-center/download-center.component";
import { GallaryComponent } from "./components/gallary/gallary.component";
import { AppreciationComponent } from "./components/appreciation/appreciation.component";
import { IntroductionComponent } from "./components/introduction/introduction.component";
import { ContactusComponent } from "./components/contactus/contactus.component";
import { AboutusComponent } from "./components/aboutus/aboutus.component";
import { FaquestionComponent } from "./components/faquestion/faquestion.component";
import { PageComponent } from "./components/page/page.component";
import { BuyComponent } from "./components/buy/buy.component";
import { VerifyComponent } from "./components/verify/verify.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full"
  },
  {
    path: "home",
    component: HomeComponent,
    children: [
      {
        path: "",
        component: HomeContentComponent
      },
      {
        path: "downloads",
        component: DownloadCenterComponent
      },
      {
        path: "article/:slug",
        component: PageComponent
      },
      {
        path: "buy",
        component: BuyComponent,
        children: [

        ]
      },
      {
        path: "verify",
        component: VerifyComponent
      },
      {
        path: "intro",
        component: IntroductionComponent
      },
      {
        path: "gallary",
        component: GallaryComponent
      },
      {
        path: "appreciation",
        component: AppreciationComponent
      },
      {
        path: "aboutus",
        component: AboutusComponent
      },
      {
        path: "faq",
        component: FaquestionComponent
      },
      {
        path: "contactus",
        component: ContactusComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class DefaultModuleRouting { }
