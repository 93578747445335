import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, forkJoin } from "rxjs";
import { config } from "../config";
import { ErrorService } from './error.service';
import { catchError, map } from 'rxjs/operators';
import { Response } from "../interfaces/response";

@Injectable({
  providedIn: "root"
})
export class BuyService {

  constructor(private http: HttpClient, private errorService: ErrorService) { }

  //get products,packages,categories[post-type,...]
  getPackagesWithProducts(): Observable<any> {
    return forkJoin([
      this.http
        .get<Response<any>>(`${config.apiUrl}/store/package/`),
      this.http
        .get<Response<any>>(`${config.apiUrl}/store/product`),
      this.http
        .get<Response<any>>(`${config.apiUrl}/store/product/extra`),
      this.http
        .get<Response<any>>(`${config.apiUrl}/store/product/category`)

    ])
      .pipe(map(response => response.map(item => { return item.data })));
  }

  //post new order
  sendOrder(data): Observable<any> {
    return this.http.post<Response<any>>(`${config.apiUrl}/store/order/new/`, data).pipe(map(response => response.data));
  }

  sendPromotionCode(data, packageId): Observable<any> {
    return this.http.get<Response<any>>(`${config.apiUrl}/store/discount/${data}/${packageId}`).pipe(
      catchError(res => this.errorService.handleError(res)),
      map(response => response.data)
    )
  }

  sendPeyDetailes(data): Observable<any> {
    let header = { "Content-Type": "application/json" }
    return this.http.post<any>(`${config.apiUrl}/store/payment/zarin/verify`, data, { headers: header })
      .pipe(
        catchError(res => this.errorService.handleError(res)),
        map(response => response.data)
      );
  }

}
