import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, ValidatorFn, Validators, FormControl } from "@angular/forms";
import { InboxService } from "../../../../services/inbox.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { SnackbarComponent } from "../../../../super/snackbar/snackbar.component";

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.scss']
})
export class ContactusComponent implements OnInit {

  messageForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
    public snackBar: MatSnackBar,
    private inboxService: InboxService) {
  }

  ngOnInit() {
    this.messageForm = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required, Validators.minLength(3)
      ]),
      phone: new FormControl('', [
        Validators.required, Validators.minLength(8)
      ]),
      subject: new FormControl('', [
        Validators.required
      ]),
      email: new FormControl('', [
        Validators.required, Validators.email
      ]),
      message: new FormControl('', [
        Validators.required, Validators.minLength(20)
      ]),
    });
  }

  sendMessage() {
    console.log(this.messageForm.valid)
    if (this.messageForm.valid) {
      let formData = this.messageForm.value;
      formData.type = 1;
      this.inboxService.sendMessage(formData).subscribe(() => {
        this.snackBar.openFromComponent(SnackbarComponent, {
          data: 'پیام با موفقیت ثبت شد',
          panelClass: 'successSnakbar',
          duration: 1000
        });
        this.messageForm.reset();
      });
    } else {
      this.snackBar.openFromComponent(SnackbarComponent, {
        data: 'تمامی فیلد ها را با دقت پر کنید.',
        panelClass: 'errorSnakbar',
        duration: 10000
      });
    }
  }
}
