import { Component, OnInit } from "@angular/core";
import { config } from "../../../../config";
import { ContentsService } from "../../../../services/contents.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import { PreviewDialog } from '../../../../super/preview-dialog/preview-dialog.component'
import {
  MatDialog
} from '@angular/material/dialog';
@Component({
  selector: "app-appreciation",
  templateUrl: "./appreciation.component.html",
  styleUrls: ["./appreciation.component.scss"]
})
export class AppreciationComponent implements OnInit {
  appreciations = [];
  contents;
  apiUrl = config.apiUrl;
  type;
  rawData;
  appreciationTabs = [{ value: 'appreciation-film', icon: 'local_movies' }, { value: 'appreciation-picture', icon: 'image' }];

  constructor(private fb: FormBuilder, private ContentsService: ContentsService, public dialog: MatDialog) {

  }
  ngOnInit() {
    // this.type = this.tab.get('appreciationType').value;
    this.type = "appreciation-film";
    this.getContents();
  }
  getContents() {
    this.ContentsService.getContents(this.type).subscribe(contents => {
      let tempArray = [];
      let appreciations = [];
      this.rawData = contents;
      this.appreciations = [];
      console.log(contents);
      if (contents) {
        if (contents.length < 4) {
          this.appreciations = contents;
        } else {
          for (let i = 0; i < contents.length; i++) {
            tempArray.push(contents[i]);
            if ((i !== 0 && (i + 1) % 3 == 0)) {
              appreciations.push(tempArray);
              tempArray = [];
            }
            else if (contents.length % 3 !== 0 && contents.length - i < 3) {
              appreciations.push(tempArray);
              tempArray = [];
            }
          }
          tempArray = [];
          for (let j = 0; j < appreciations.length; j++) {
            tempArray.push(appreciations[j]);
            if ((j !== 0 && (j + 1) % 2 == 0) || appreciations.length - j < 2) {
              this.appreciations.push(tempArray);
              tempArray = [];
            }
          }
        }
      }
    });
  }
  showImage(target): void {
    let targetIndex = 0;
    targetIndex = this.rawData.indexOf(target);
    const dialogRef = this.dialog.open(PreviewDialog, {
      width: 'auto',
      data: { allItems: this.rawData, index: targetIndex }
    });
  }
  changeTab(tab) {
    if (this.type !== tab.value) {
      this.type = tab.value;
      this.getContents();
    }
  }
  customOptions2 = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 1,
    navText: ["قبلی", "بعدی"],
    nav: false
  };
  mobileOptions = {
    rtl: true,
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 30,
    autoplay: false,
    items: 1,
    navText: ["قبلی", "بعدی"],
    nav: false,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      }
    }
  }
}
