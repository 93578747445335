import { Pipe, PipeTransform } from "@angular/core";
import { config } from "../config"
/*
 * transforms a decimal number to currency unit
 * Usage:
 *   value | currencyUnit
 * Example:
 *   {{ 5000 | currencyUnit }}
 *   formats to: "تومان ۵,۰۰۰"
 * ---------------------------------------------
 * #link: https://stackoverflow.com/questions/2901102/how-to-print-a-number-with-commas-as-thousands-separators-in-javascript
*/
@Pipe({ name: 'currencyUnit' })
export class CurrencyUnitPipe implements PipeTransform {
	constructor(
	) {
	}

	transform(value: any): string {
		if (!value) {
			value = 0
		} else if (value < 0) {
			value = Math.abs(+value);
		}

		return value
			.toFixed(0)
			.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
	}
}
