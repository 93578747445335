import { Component } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA
} from '@angular/material/dialog';

@Component({
  selector: 'loading-buy-dialog',
  templateUrl: 'loading-buy-dialog.html',
  styleUrls: ['./loading-buy-dialog.scss']
})
export class LoadingBuyDialog {
  index = 0;

  constructor(
    public dialogRef: MatDialogRef<LoadingBuyDialog>
  ) { }
  ngOnInit() {

  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}