import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, forkJoin} from "rxjs";
import {config} from "../config";
import {ErrorService} from './error.service';
import {catchError, map} from 'rxjs/operators';
import {Response} from "../interfaces/response";

@Injectable({
  providedIn: "root"
})
export class InboxService {

  constructor(private http: HttpClient, private errorService: ErrorService) {
  }

  sendMessage(data): Observable<any> {
    return this.http.post<Response<any>>(`${config.apiUrl}/inbox`, data).pipe(map(response => response.data));
  }
}
